<template>
<div class="Enterprise">
  <div class="inner-banner" style="margin-bottom: 0px;">
    <div class="overlay">
      <div class="container clearfix">
	<h2>Enterprise</h2>
	<ul>
	  <li>
	    <router-link to="/" class="nav-link"> Home </router-link>
	  </li>
	  <li>/</li>
	  <li>Enterprise</li>
	</ul>
      </div> <!-- /.container -->
    </div> <!-- /.overlay -->
  </div> <!-- /.inner-banner -->
  
  
  
  <div class="our-portfolio portfolio-grid-v3" style="padding-top:80px; background: #f6f6f6;">
    <div class="container">
      <!-- End of .mixitUp-menu -->
      
      <div class="row" id="mixitUp-item">
	
	<div class="col-lg-6 col-sm-6 col-12 mix personal other" style="display: inline-block;" data-bound="">

	  <div class="single-item">
	      <div class="image-box">
		<div class="img company-splash"><img src="images/p1.png" alt=""></div>
		<div class="overlay">
		  <div class="overlay-text">
		    <h4><a target="_blank" href="https://thras.io/">Thras.io</a></h4>
		    <p>Product and Operational Execution for the largest acquirer of Amazon third party sellers.</p>
		  </div> <!-- /.overlay-text -->
		</div> <!-- /.overlay -->
	      </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->
	</div> <!-- /.col-md-6 -->
	<div class="col-lg-6 col-sm-6 col-12 mix carloan" style="display: inline-block;" data-bound="">
	  <div class="single-item">
	    <div class="image-box">
	      <div class="img company-splash"><img src="images/nuance.png" alt=""></div>
	      <div class="overlay">
		<div class="overlay-text">
		  <h4><a target="_blank" href="https://www.nuance.com/">Nuance</a></h4>
		  <p>VP of Product for the leading speech technology company.</p>
		</div> <!-- /.overlay-text -->
	      </div> <!-- /.overlay -->
	    </div> <!-- /.image-box -->
	  </div> <!-- /.single-item -->

	  
	</div> <!-- /.col-md-6 -->
	
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div>
</div>
</template>


<script>
  export default {
    title: 'Enterprise - Ten Factor Growth'
}
</script>
</script>

<style scoped>
@import './company-splash.css';

</style>

